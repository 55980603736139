body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.commentTextarea:focus {
  outline: none !important;
  border: solid rgb(0, 0, 0);
  border-width: 0px 0px 2px 0px;
}

.commentTextarea {
  overflow: hidden;
  width: 100%;
  border: solid #ccc;
  border-width: 0px 0px 1px 0px;
  margin-bottom: 5px;
}

@media only screen and (min-width: 768px) {
  .mobileMenu {
    display: none !important;
  }
}

@media only screen and (max-width: 767px) {
  .desktopMenu {
    display: none !important;
  }
}